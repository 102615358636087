import { guid } from './guid';

/** @see stolen from https://stackoverflow.com/a/4819886/1138860 */
function isTouchDevice(): boolean {
    const w = window as any;
    const prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');
    const mq = (query: any) => window.matchMedia(query).matches;

    if (
        'ontouchstart' in window ||
        (w.DocumentTouch && document instanceof w.DocumentTouch)
    ) {
        return true;
    }

    // include the 'heartz' as a way to have a non matching MQ to help terminate the join
    // https://git.io/vznFH
    const query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join(
        ''
    );

    return mq(query);
}

class Point {
    public x = 0;
    public y = 0;

    constructor(x = 0, y = 0) {
        this.x = x;
        this.y = y;
    }

    public reset() {
        this.x = 0;
        this.y = 0;
    }

    public compare(otherPoint: Point, threshold = 10) {
        let success = true;

        // x
        if (
            otherPoint.x > this.x + threshold ||
            otherPoint.x + threshold < this.x
        ) {
            success = false;
        }

        // y
        if (
            otherPoint.y > this.y + threshold ||
            otherPoint.y + threshold < this.y
        ) {
            success = false;
        }

        return success;
    }
}

const events = new Map<string, { ref: HTMLElement; callback(e: any): void }>();

export function deviceClick(ref: HTMLElement, callback: (e: any) => void) {
    const id = guid();

    if (!events.has(id)) {
        const point = new Point();
        let timestamp = 0;
        let eventCallback = callback;

        const ontouchend = (e: TouchEvent) => {
            ref.removeEventListener('touchend', ontouchend);

            const item = e.changedTouches.item(0);

            if (item && e.timeStamp - timestamp <= 500) {
                const newPoint = new Point(item.clientX, item.clientY);

                if (point.compare(newPoint)) {
                    callback(e);
                }
            }

            point.reset();
        };

        const ontouchstart = (e: TouchEvent) => {
            timestamp = e.timeStamp;

            const item = e.touches.item(0);

            if (item) {
                point.x = item.clientX;
                point.y = item.clientY;
            }

            ref.addEventListener('touchend', ontouchend, false);
        };

        if (isTouchDevice()) {
            ref.addEventListener('touchstart', ontouchstart, false);

            eventCallback = ontouchstart;
        } else {
            ref.addEventListener('click', callback, false);
        }

        events.set(id, { callback: eventCallback, ref });
    }

    return () => {
        events.forEach(({ ref, callback }, refId) => {
            if (refId === id) {
                ref.removeEventListener('click', callback);
                ref.removeEventListener('touchstart', callback);

                events.delete(refId);
            }
        });
    };
}
