import * as spaces from './space.json';

export type SpaceSize = 'XXS' | 'XS' | 'S' | 'M' | 'L' | 'XL' | 'XXL' | 0;

// tslint:disable-next-line:cyclomatic-complexity
function mapSpaceSize(size?: SpaceSize): string {
    return spaces[`SP_${size}`] || '0px';
}

export function getSpace(size?: SpaceSize | SpaceSize[]): string {
    if (Array.isArray(size)) {
        return size.map(mapSpaceSize).join(' ');
    } else {
        return mapSpaceSize(size);
    }
}

export function calcSpace(add: number, size?: SpaceSize | SpaceSize[]): string {
    const space = getSpace(size);

    return `${parseInt(space, 10) + add}px`;
}
