import { Pretend, IPretendRequest, Get } from 'pretend';

import * as dtos from './studio-dtos';

export interface AdditionalMenuEntryController {
    getAdditionalMenuEntries(): Promise<dtos.SelectEntryDto[]>;
}

export interface BookingOptionController {
    getAvailableBookingOptions(): Promise<dtos.SelectEntryDto[]>;
}

export interface PublicFacilityController {
    getAvailableCategories(): Promise<dtos.SelectEntryDto[]>;
}

export interface StudioCategoryController {
    getAvailableStudioCategories(): Promise<dtos.SelectEntryDto[]>;
}

export interface StudioController {
    getSingleStudio(
        alias: string,
        query: { bookingPortal?: boolean }
    ): Promise<dtos.StudioDetailsDto>;

    getStudioCount(query: {
        name?: string;
        city?: string;
        category?: string[];
        feature?: string[];
        bookingOption?: string[];
        visible?: boolean;
        visibleForStudioConnect?: boolean;
        profileComplete?: boolean;
        contractActive?: boolean;
        geoRequestType?: string;
        latitude?: number[];
        longitude?: number[];
        radius?: number;
        page?: number;
        size?: number;
    }): Promise<dtos.StudioCountResponseDto>;

    getStudioRating(externalId: string): Promise<dtos.StudioRatingInfoDto>;

    getStudiosCountPerCity(): Promise<dtos.StudioCount[]>;
}

export interface StudioFeatureCategoryController {
    getAvailableStudioFeatureCategories(query: {
        featurePerCategoryLimit?: number;
    }): Promise<dtos.StudioFeatureCategoryDto[]>;
}

export interface StudioSearchController {
    getStudioInfos(query: {
        name?: string;
        visible?: boolean;
        visibleForStudioConnect?: boolean;
        geoRequestType?: string;
        latitude?: number[];
        longitude?: number[];
        radius?: number;
        page?: number;
        size?: number;
    }): Promise<dtos.MsPageStudioSearchDto>;

    getStudiosForBookingPortal(query: {
        name?: string;
        city?: string;
        category?: string[];
        feature?: string[];
        bookingOption?: string[];
        visible?: boolean;
        visibleForStudioConnect?: boolean;
        profileComplete?: boolean;
        contractActive?: boolean;
        geoRequestType?: string;
        latitude?: number[];
        longitude?: number[];
        radius?: number;
        page?: number;
        size?: number;
    }): Promise<dtos.MsPageStudioSearchDto>;

    getStudiosForFilters(query: {
        name?: string;
        visible?: boolean;
        visibleForStudioConnect?: boolean;
        geoRequestType?: string;
        latitude?: number[];
        longitude?: number[];
        radius?: number;
        page?: number;
        size?: number;
    }): Promise<dtos.MsPageStudioFilterDto>;

    getStudiosForMySportsGuest(query: {
        name?: string;
        visible?: boolean;
        visibleForStudioConnect?: boolean;
        profileComplete?: boolean;
        contractActive?: boolean;
        geoRequestType?: string;
        latitude?: number[];
        longitude?: number[];
        radius?: number;
        page?: number;
        size?: number;
    }): Promise<dtos.MsPageStudioSearchDto>;

    getStudiosForStudioConnect(query: {
        name?: string;
        visible?: boolean;
        visibleForStudioConnect?: boolean;
        profileComplete?: boolean;
        contractActive?: boolean;
        geoRequestType?: string;
        latitude?: number[];
        longitude?: number[];
        radius?: number;
        page?: number;
        size?: number;
    }): Promise<dtos.MsPageStudioSearchDto>;
}

export interface WhitelabelStudioSearchController {
    getStudiosForWhitelabelPortal(query: {
        visible?: boolean;
        geoRequestType?: string;
        latitude?: number[];
        longitude?: number[];
        radius?: number;
        page?: number;
        size?: number;
    }): Promise<dtos.MsPageStudioSearchDto>;
}

export interface MySportsStudio {
    AdditionalMenuEntryController: AdditionalMenuEntryController;
    BookingOptionController: BookingOptionController;
    PublicFacilityController: PublicFacilityController;
    StudioCategoryController: StudioCategoryController;
    StudioController: StudioController;
    StudioFeatureCategoryController: StudioFeatureCategoryController;
    StudioSearchController: StudioSearchController;
    WhitelabelStudioSearchController: WhitelabelStudioSearchController;
}

// tslint:disable:no-any

class AdditionalMenuEntryControllerBlueprint
    implements AdditionalMenuEntryController {
    @Get('/v1/public/studios/additionalmenuentries')
    public getAdditionalMenuEntries(): any {
        /**/
    }
}

class BookingOptionControllerBlueprint implements BookingOptionController {
    @Get('/v1/public/studios/bookingoptions')
    public getAvailableBookingOptions(): any {
        /**/
    }
}

class PublicFacilityControllerBlueprint implements PublicFacilityController {
    @Get('/v1/public/facilities/categories')
    public getAvailableCategories(): any {
        /**/
    }
}

class StudioCategoryControllerBlueprint implements StudioCategoryController {
    @Get('/v1/public/studios/categories')
    public getAvailableStudioCategories(): any {
        /**/
    }
}

class StudioControllerBlueprint implements StudioController {
    @Get('/v1/public/studios/{alias}', true)
    public getSingleStudio(_p0: any, _p1: any): any {
        /**/
    }

    @Get('/v1/public/studios/count', true)
    public getStudioCount(_p0: any): any {
        /**/
    }

    @Get('/v1/public/studios/{externalId}/rating')
    public getStudioRating(_p0: any): any {
        /**/
    }

    @Get('/v1/public/studios/countbycity')
    public getStudiosCountPerCity(): any {
        /**/
    }
}

class StudioFeatureCategoryControllerBlueprint
    implements StudioFeatureCategoryController {
    @Get('/v1/public/studios/featureCategories', true)
    public getAvailableStudioFeatureCategories(_p0: any): any {
        /**/
    }
}

class StudioSearchControllerBlueprint implements StudioSearchController {
    @Get('/v1/public/studios', true)
    public getStudioInfos(_p0: any): any {
        /**/
    }

    @Get('/v1/public/studios/forbookingportal', true)
    public getStudiosForBookingPortal(_p0: any): any {
        /**/
    }

    @Get('/v1/public/studios/forfilters', true)
    public getStudiosForFilters(_p0: any): any {
        /**/
    }

    @Get('/v1/public/studios/formysportsguest', true)
    public getStudiosForMySportsGuest(_p0: any): any {
        /**/
    }

    @Get('/v1/public/studios/forstudioconnect', true)
    public getStudiosForStudioConnect(_p0: any): any {
        /**/
    }
}

class WhitelabelStudioSearchControllerBlueprint
    implements WhitelabelStudioSearchController {
    @Get('/v1/public/studios/forwhitelabelportal', true)
    public getStudiosForWhitelabelPortal(_p0: any): any {
        /**/
    }
}

// tslint:enable:no-any

export function getClient(
    endpoint: string,
    token: string | undefined,
    configure: (client: Pretend) => Pretend = client => client
): MySportsStudio {
    const bearerToken = (request: IPretendRequest) => {
        if (token) {
            request.options.headers = new Headers(request.options.headers);
            request.options.headers.set('Authorization', `bearer ${token}`);
        }
        return request;
    };

    return {
        AdditionalMenuEntryController: configure(Pretend.builder())
            .requestInterceptor(bearerToken)
            .target(AdditionalMenuEntryControllerBlueprint, endpoint),
        BookingOptionController: configure(Pretend.builder())
            .requestInterceptor(bearerToken)
            .target(BookingOptionControllerBlueprint, endpoint),
        PublicFacilityController: configure(Pretend.builder())
            .requestInterceptor(bearerToken)
            .target(PublicFacilityControllerBlueprint, endpoint),
        StudioCategoryController: configure(Pretend.builder())
            .requestInterceptor(bearerToken)
            .target(StudioCategoryControllerBlueprint, endpoint),
        StudioController: configure(Pretend.builder())
            .requestInterceptor(bearerToken)
            .target(StudioControllerBlueprint, endpoint),
        StudioFeatureCategoryController: configure(Pretend.builder())
            .requestInterceptor(bearerToken)
            .target(StudioFeatureCategoryControllerBlueprint, endpoint),
        StudioSearchController: configure(Pretend.builder())
            .requestInterceptor(bearerToken)
            .target(StudioSearchControllerBlueprint, endpoint),
        WhitelabelStudioSearchController: configure(Pretend.builder())
            .requestInterceptor(bearerToken)
            .target(WhitelabelStudioSearchControllerBlueprint, endpoint)
    };
}
