import { LocalDate } from '@spa-frontend/date-lib';
import { h, Component } from 'preact';
import { getCoursePlanPublic, CourseGroups, Course, getStudios } from '../api';
import { StudioExtendedDto } from '../api/dtos';
import { CourseBox } from '../components/course-box';
import { Layout } from '../components/layout';
import { Modal } from '../components/modal';
import Spinner from '../components/spinner';
import { Text } from '../components/text';
import {
    onResize,
    config,
    onViewState,
    ViewState,
    postMessage,
    getMessage
} from '../config';
import { i18n } from '../i18n';
import { classnames } from '../utils/classnames';
import {
    emptyCourses,
    groupCoursesByDate,
    getCourseHeaderDates,
    filterCourseGroupsByDaytime
} from '../utils/courses';
import { CourseDetail } from './detail';
import { CoursesFilter } from './filter';
import { CoursesHeader } from './header';
import styles from './index.css';

interface CoursesProps {
    onContentHeight(height: number): void;
}

interface CoursesState {
    modalContent?: Course;
    modalHeight: number;
    modalTop: number;
    organizationUnitId?: number;
    studios?: StudioExtendedDto[];
    viewState?: ViewState;
    courseDate: LocalDate;
    coursesLoading: boolean;
    coursesFetched: boolean;
    coursesError?: boolean;
    courseGroupsHeader: LocalDate[];
    courseGroupsMorning: CourseGroups;
    courseGroupsNoon: CourseGroups;
    courseGroupsAfternoon: CourseGroups;
    courseGroupsEvening: CourseGroups;
}

export class Courses extends Component<CoursesProps, CoursesState> {
    private contentEl?: HTMLDivElement;
    private resizeDisposer?: () => void;
    private viewStateDisposer?: () => void;
    private studiosXhr?: Promise<StudioExtendedDto[]>;

    private get preloadDays() {
        const { viewState } = this.state;

        switch (viewState) {
            default:
            case 'mobile':
                return 1;
            case 'tablet':
                return 4;
            case 'desktop':
                return 7;
        }
}

    private get contentClassName() {
        return classnames(
            styles.content,
            config.height !== 'auto' && styles.maxHeight100
        );
    }

    private get containerClassName() {
        const mobile = this.state.viewState === 'mobile';

        return classnames(styles.container, mobile && styles.containerMobile);
    }

    private get now() {
        return LocalDate.now(
            this.state.studios?.find(
                s => s.id === this.state.organizationUnitId
            )?.zoneId! || config.zoneId
        );
    }

    constructor(props: CoursesProps) {
        super(props);

        this.state = {
            modalTop: 0,
            modalHeight: 0,
            modalContent: undefined,
            organizationUnitId: config.organizationUnitId!,
            studios: [],
            courseDate: this.now.startOf('week'),
            coursesLoading: false,
            coursesFetched: false,
            courseGroupsHeader: [],
            courseGroupsMorning: {},
            courseGroupsNoon: {},
            courseGroupsAfternoon: {},
            courseGroupsEvening: {}
        };

        this.onContentRef = this.onContentRef.bind(this);
        this.onLoadPrev = this.onLoadPrev.bind(this);
        this.onLoadNext = this.onLoadNext.bind(this);
        this.onModalClose = this.onModalClose.bind(this);
        this.onModalHeight = this.onModalHeight.bind(this);
        this.onClickCourse = this.onClickCourse.bind(this);
        this.onChangeStudio = this.onChangeStudio.bind(this);
        this.renderCourse = this.renderCourse.bind(this);
    }

    public componentDidMount() {
        this.loadStudios();

        this.resizeDisposer = onResize(() => this.measureContent());
        this.viewStateDisposer = onViewState(viewState =>
            this.setState({ viewState })
        );
    }

    public componentDidUpdate(_: CoursesProps, prevState: CoursesState) {
        const { viewState, modalHeight, organizationUnitId } = this.state;

        if (
            prevState.viewState !== viewState ||
            prevState.organizationUnitId !== organizationUnitId
        ) {
            this.loadCourses(this.state.courseDate, 'INITIAL');
        }

        if (prevState.modalHeight === 0 && modalHeight > 0) {
            this.measureContent();
        }
    }

    public componentWillUnmount() {
        if (this.resizeDisposer) {
            this.resizeDisposer();
        }

        if (this.viewStateDisposer) {
            this.viewStateDisposer();
        }
    }

    public render() {
        const { coursesError } = this.state;

        return coursesError ? (
            <Text>{i18n.__('errormessage.internal.server.error')}</Text>
        ) : (
            <div>
                {this.renderContent()}
                {this.renderModal()}
            </div>
        );
    }

    private renderContent() {
        const {
            coursesLoading,
            coursesFetched,
            courseGroupsHeader,
            organizationUnitId,
            viewState,
            studios
        } = this.state;
        const mobile = viewState === 'mobile';

        return (
            <Layout stack between="M">
                <CoursesFilter
                    studios={studios || []}
                    organizationUnitId={organizationUnitId}
                    onChangeStudio={this.onChangeStudio}
                />
                <Layout stack between="M" className={this.containerClassName}>
                    <CoursesHeader
                        now={this.now}
                        dates={courseGroupsHeader}
                        mobile={mobile}
                        loading={coursesLoading}
                        onNext={this.onLoadNext}
                        onPrev={this.onLoadPrev}
                    />
                    {coursesLoading ? (
                        <Spinner className={styles.spinner} />
                    ) : null}
                    {coursesFetched ? (
                        <div
                            className={this.contentClassName}
                            ref={this.onContentRef}
                        >
                            {this.renderBody()}
                            {this.renderEmpty()}
                        </div>
                    ) : null}
                </Layout>
            </Layout>
        );
    }

    private renderBody() {
        const {
            courseGroupsMorning,
            courseGroupsNoon,
            courseGroupsAfternoon,
            courseGroupsEvening
        } = this.state;
        const emptyMorningCourses = emptyCourses(courseGroupsMorning);
        const emptyNoonCourses = emptyCourses(courseGroupsNoon);
        const emptyAfternoonCourses = emptyCourses(courseGroupsAfternoon);
        const emptyEveningCourses = emptyCourses(courseGroupsEvening);

        return (
            <Layout stack between="L">
                {emptyMorningCourses ? null : (
                    <Layout stack between="M">
                        {this.renderDaytime(
                            // prettier-ignore
                            `${i18n.__('commons.morning')}`
                        )}
                        {this.renderCourseDaytime(courseGroupsMorning)}
                    </Layout>
                )}
                {emptyNoonCourses ? null : (
                    <Layout stack between="M">
                        {this.renderDaytime(
                            // prettier-ignore
                            `${i18n.__('commons.noon')}`
                        )}
                        {this.renderCourseDaytime(courseGroupsNoon)}
                    </Layout>
                )}
                {emptyAfternoonCourses ? null : (
                    <Layout stack between="M">
                        {this.renderDaytime(
                            // prettier-ignore
                            `${i18n.__('commons.afternoon')}`
                        )}
                        {this.renderCourseDaytime(courseGroupsAfternoon)}
                    </Layout>
                )}
                {emptyEveningCourses ? null : (
                    <Layout stack between="M">
                        {this.renderDaytime(
                            // prettier-ignore
                            `${i18n.__('commons.evening')}`
                        )}
                        {this.renderCourseDaytime(courseGroupsEvening)}
                    </Layout>
                )}
            </Layout>
        );
    }

    private renderEmpty() {
        const {
            coursesFetched,
            coursesLoading,
            courseGroupsMorning,
            courseGroupsNoon,
            courseGroupsAfternoon,
            courseGroupsEvening
        } = this.state;

        if (coursesFetched && !coursesLoading) {
            const emptyMorningCourses = emptyCourses(courseGroupsMorning);
            const emptyNoonCourses = emptyCourses(courseGroupsNoon);
            const emptyAfternoonCourses = emptyCourses(courseGroupsAfternoon);
            const emptyEveningCourses = emptyCourses(courseGroupsEvening);

            if (
                emptyMorningCourses &&
                emptyNoonCourses &&
                emptyAfternoonCourses &&
                emptyEveningCourses
            ) {
                return (
                    <Layout padding="M" style={{ minHeight: 200 }}>
                        <Text>{i18n.__('noxweb.courses.empty.title')}</Text>
                    </Layout>
                );
            }
        }

        return null;
    }

    private renderDaytime(text: string) {
        return (
            <Layout
                className={styles.daytimeHeader}
                padding={['XS', 0]}
                hAlign="center"
            >
                <Text
                    bold
                    color="coolgrey"
                    className={styles.daytimeHeaderText}
                >
                    {text}
                </Text>
            </Layout>
        );
    }

    private renderCourseDaytime(courseGroups: CourseGroups) {
        const { viewState } = this.state;

        return (
            <Layout
                between="M"
                padding={[0, viewState === 'mobile' ? 'M' : 'L']}
            >
                {Object.keys(courseGroups).map(key => {
                    const courses = courseGroups[key];

                    return (
                        <Layout
                            stack
                            key={`course-group-${key}`}
                            between="M"
                            className={styles.daytime}
                        >
                            {courses.map(this.renderCourse)}
                        </Layout>
                    );
                })}
            </Layout>
        );
    }

    private renderCourse(course: Course) {
        return (
            <CourseBox
                key={`course-${course.id}`}
                course={course}
                onClick={this.onClickCourse}
            />
        );
    }

    private renderModal() {
        const { modalContent, modalTop, viewState } = this.state;

        return (
            <Modal
                open={Boolean(modalContent)}
                top={modalTop}
                onDismiss={this.onModalClose}
                onHeight={this.onModalHeight}
            >
                {modalContent ? (
                    <CourseDetail
                        viewState={viewState!}
                        course={modalContent}
                    />
                ) : null}
            </Modal>
        );
    }

    private async loadStudios() {
        this.studiosXhr = getStudios();

        const studios = await this.studiosXhr;

        this.setState({ studios });
    }

    private async loadCourses(
        startDate: LocalDate,
        status: 'NEXT' | 'PREV' | 'INITIAL',
        tries = 0
    ) {
        if (this.studiosXhr) {
            await this.studiosXhr;
        }

        this.setState(
            { coursesLoading: true, coursesFetched: false },
            async () => {
                try {
                    const { viewState, organizationUnitId } = this.state;
                    const { now } = this;

                    if (viewState === 'desktop') {
                        startDate = startDate.startOf('week');
                    } else {
                        if (startDate.isBefore(now)) {
                            startDate = now;
                        }
                    }

                    const endDate = startDate.addDays(this.preloadDays - 1);
                    
                    const courses = await getCoursePlanPublic(
                        startDate,
                        endDate,
                        organizationUnitId ? [organizationUnitId] : [],
                        config.showCanceled
                    );

                    // empty handling
                    if (courses.length === 0 && tries >= 0) {
                        if (tries <= 10) {
                            let newDate = now;

                            if (status === 'NEXT') {
                                newDate = startDate.addDays(this.preloadDays);
                            } else if (status === 'PREV') {
                                newDate = startDate.subDays(this.preloadDays);
                            }

                            this.loadCourses(newDate, status, ++tries);
                        } else {
                            const newDate = (() => {
                                switch (status) {
                                    case 'INITIAL':
                                        return this.state.courseDate;
                                    case 'NEXT':
                                        return this.state.courseDate.addDays(
                                            this.preloadDays
                                        );
                                    case 'PREV':
                                        return this.state.courseDate.subDays(
                                            this.preloadDays
                                        );
                                }
                            })();

                            this.loadCourses(newDate, status, -1);
                        }

                        return;
                    }

                    const courseGroups = groupCoursesByDate(courses, [
                        startDate,
                        endDate
                    ]);
                    const courseGroupsHeader = getCourseHeaderDates(
                        courseGroups
                    );

                    this.setState({
                        courseGroupsMorning: filterCourseGroupsByDaytime(
                            courseGroups,
                            'morning'
                        ),
                        courseGroupsNoon: filterCourseGroupsByDaytime(
                            courseGroups,
                            'noon'
                        ),
                        courseGroupsAfternoon: filterCourseGroupsByDaytime(
                            courseGroups,
                            'afternoon'
                        ),
                        courseGroupsEvening: filterCourseGroupsByDaytime(
                            courseGroups,
                            'evening'
                        ),
                        courseGroupsHeader,
                        courseDate: startDate,
                        coursesLoading: false,
                        coursesFetched: true
                    });
                } catch (e) {
                    console.error(e);

                    this.setState({
                        coursesLoading: false,
                        coursesError: true
                    });
                }
            }
        );
    }

    private measureContent() {
        if (config.height !== 'auto') {
            return;
        }

        const { modalContent, modalHeight, modalTop } = this.state;
        const DATE_HEADER_HEIGHT = 90;
        const FILTER_HEIGHT = 60;

        const int = setInterval(() => {
            if (this.contentEl && this.contentEl.scrollHeight) {
                clearInterval(int);

                let contentHeight =
                    this.contentEl.scrollHeight +
                    DATE_HEADER_HEIGHT +
                    FILTER_HEIGHT;

                if (modalContent && modalHeight) {
                    const neededModalSpace = Math.round(
                        modalTop + modalHeight + 20
                    );

                    if (neededModalSpace >= window.innerHeight) {
                        contentHeight = neededModalSpace;
                    } else {
                        contentHeight = Math.max(
                            contentHeight,
                            modalHeight + 100
                        );
                    }
                }

                this.props.onContentHeight(contentHeight);
            }
        }, 16);
    }

    private onLoadPrev() {
        const date = this.state.courseDate.subDays(this.preloadDays);

        this.loadCourses(date, 'PREV');
    }

    private onLoadNext() {
        const date = this.state.courseDate.addDays(this.preloadDays);

        this.loadCourses(date, 'NEXT');
    }

    private onContentRef(el?: HTMLDivElement) {
        this.contentEl = el;

        if (this.contentEl) {
            this.measureContent();
        }
    }

    private onClickCourse(course: Course) {
        getMessage<number>('ms-nox-message-modal-top', top => {
            const modalTop = top + 57; // close-X offset

            this.setState({ modalTop, modalContent: course }, () =>
                this.measureContent()
            );
        });

        // request modal-top value from its parent
        postMessage(null, 'ms-nox-message-request-modal-top');
    }

    private onChangeStudio(id?: number) {
        this.setState({
            organizationUnitId: id
        });
    }

    private onModalClose() {
        this.setState(
            { modalContent: undefined, modalTop: 0, modalHeight: 0 },
            () => this.measureContent()
        );
    }

    private onModalHeight(modalHeight: number) {
        this.setState({ modalHeight }, () => this.measureContent());
    }
}
