import { Component, h } from 'preact';
import { getImage } from 'spa-cdn-image-util';
import { Course } from '../api';
import { Avatar } from '../components/avatar';
import { Button } from '../components/button';
import { CalendarItem } from '../components/calendar-item';
import { H1 } from '../components/headline';
import { Layout } from '../components/layout';
import { Text } from '../components/text';
import { ViewState, config } from '../config';
import { i18n } from '../i18n';
import styles from './detail.css';
import { getEmployeeFullName } from '../utils/employee';

interface CourseDetailProps {
    course: Course;
    viewState: ViewState;
}

export class CourseDetail extends Component<CourseDetailProps> {
    private get trainerImage() {
        return this.props.course.employees[0]?.image
            ? getImage(
                  'https://cdn.mysports.com',
                  this.props.course.employees[0].image,
                  { resize: { width: 50 } }
              )
            : '';
    }

    private get trainerName() {
        return this.props.course.employees
            .map(employee => getEmployeeFullName(employee))
            .join(', ');
    }

    private get trainerDescription() {
        const set = this.props.course.employees.reduce((memo, employee) => {
            employee.employeeExpertises.forEach(expertise =>
                memo.add(expertise)
            );

            return memo;
        }, new Set<string>());

        return Array.from(set).join(', ');
    }

    private get studioImage() {
        return this.props.course.studio.logoUrl;
    }

    private get studioName() {
        return this.props.course.studio.name;
    }

    private get studioAddress() {
        const { address } = this.props.course.studio;
        return (
            <Layout stack between="XS">
                <Text size="S">
                    {address.street} {address.houseNumber} {address.addition}
                </Text>
                <Text size="S">
                    {address.zip} {address.city}
                </Text>
            </Layout>
        );
    }

    private get date() {
        const dateFormatter = new Intl.DateTimeFormat(i18n.currentLocale, {
            dateStyle: 'full'
        });

        return dateFormatter.format(this.props.course.startDate.toDate());
    }

    private get time() {
        const timeFormatter = new Intl.DateTimeFormat(i18n.currentLocale, {
            hour: '2-digit',
            minute: '2-digit'
        });

        // prettier-ignore
        return `${timeFormatter.format(this.props.course.startDate.toDate())} - ${timeFormatter.format(this.props.course.endDate.toDate())}`;
    }

    public render() {
        const { course } = this.props;
        const mobile = this.props.viewState === 'mobile';
        const { disableEmployeeExpertises = false } = config.urlParams;

        return (
            <Layout stack>
                <div
                    className={styles.image}
                    style={{ backgroundImage: `url(${course.imageUrl}` }}
                />
                <Layout between="M" stack>
                    <Layout stack between="L" padding="M">
                        <Layout stack between="M">
                            <H1>{course.name}</H1>
                            {course.description ? (
                                <Text size="S">{course.description}</Text>
                            ) : null}
                        </Layout>
                        <Layout stack={mobile} between="L">
                            <Layout stretch between="M">
                                <CalendarItem
                                    day={course.startDate.getDate()}
                                    month={course.startDate.format('MMM')}
                                />
                                <Layout
                                    stack
                                    between="XS"
                                    padding={['XS', 0, 0]}
                                >
                                    <Text bold size="S">
                                        {this.date}
                                    </Text>
                                    <Text size="XS">{this.time}</Text>
                                </Layout>
                            </Layout>
                            {this.trainerName && (
                                <Layout stretch between="M">
                                    <Avatar
                                        src={this.trainerImage}
                                        type="circle"
                                    />
                                    <Layout
                                        stack
                                        between="XS"
                                        padding={['XS', 0, 0]}
                                    >
                                        <Text bold size="S">
                                            {this.trainerName}
                                        </Text>
                                        {disableEmployeeExpertises ? null : (
                                            <Text size="XS">
                                                {this.trainerDescription}
                                            </Text>
                                        )}
                                    </Layout>
                                </Layout>
                            )}
                            <Layout stretch between="M">
                                <Avatar
                                    src={this.studioImage}
                                    type="round-corner"
                                />
                                <Layout
                                    stack
                                    between="XS"
                                    padding={['XS', 0, 0]}
                                >
                                    <Text bold size="S">
                                        {this.studioName}
                                    </Text>
                                    {this.studioAddress}
                                </Layout>
                            </Layout>
                        </Layout>
                    </Layout>
                    {course.bookable ? (
                        <Layout
                            className={styles.footer}
                            padding={['M', 'L']}
                            hAlign="flex-end"
                        >
                            {this.renderBookingButton()}
                        </Layout>
                    ) : null}
                </Layout>
                <div className={styles.gradient} />
            </Layout>
        );
    }

    private renderBookingButton() {
        const { tenant, baseHost } = config;
        const studioId = this.props.course.studio.id;
        const alias = encodeURIComponent(btoa(`${tenant}:${studioId}`));
        const { course } = this.props;
        const href = `https://${baseHost}/studio/${alias}/booking/course/${course.id}`;

        return (
            <a target="_blank" href={href}>
                <Button primary>{i18n.__('noxweb.book.now')}</Button>
            </a>
        );
    }
}
