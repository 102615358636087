import { h, Component } from 'preact';
import { classnames } from '../../utils/classnames';
import { getColor, ColorType } from '../vars/color';
import styles from './index.css';

export interface SpinnerProps {
    className?: string;
    color?: ColorType;
    style?: any;
}

export class Spinner extends Component<SpinnerProps> {
    public render(): JSX.Element {
        const { color = 'black', style } = this.props;
        const colorStyle = { borderColor: getColor(color) };
        const className = classnames(styles.spinner, this.props.className);

        return (
            <div className={className} style={{ ...style, ...colorStyle }} />
        );
    }
}

export default Spinner;
