import { LocalDate, toLocalDate } from '@spa-frontend/date-lib';
import { Course, CourseGroups, Daytime } from '../api';

export function groupCoursesByDate(
    courses: Course[],
    [startDate, endDate]: [LocalDate, LocalDate]
) {
    const groups = courses.reduce((memo, course) => {
        const key = toLocalDate(course.startDate).toString();

        if (!memo[key]) memo[key] = [];

        memo[key] = [...memo[key], course];

        return memo;
    }, {} as CourseGroups);

    let date = startDate;
    const sortedGroups: CourseGroups = {};

    while (date <= endDate) {
        const key = date.toString();

        if (!groups[key]) {
            sortedGroups[key] = [];
        } else {
            sortedGroups[key] = groups[key];
        }

        date = date.addDays(1);
    }

    return sortedGroups;
}

export function getCourseHeaderDates(courseGroups: CourseGroups) {
    return Object.keys(courseGroups).map(key => toLocalDate(key));
}

export function filterCourseGroupsByDaytime(
    courses: CourseGroups,
    daytime: Daytime
) {
    const newCourses: CourseGroups = {};

    Object.entries(courses).forEach(([key, courses]) => {
        newCourses[key] = courses.filter(course => {
            const hours = course.startDate.getHours();

            switch (daytime) {
                case 'morning':
                    return hours >= 0 && hours < 12;
                case 'noon':
                    return hours >= 12 && hours < 14;
                case 'afternoon':
                    return hours >= 14 && hours < 18;
                case 'evening':
                    return hours >= 18 && hours <= 23;
            }
        });
    });

    return newCourses;
}

export function emptyCourses(courseGroups: CourseGroups) {
    return (
        Object.values(courseGroups).some(courses => courses.length !== 0) ===
        false
    );
}
