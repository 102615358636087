import { h } from 'preact';
import { classnames } from '../../utils/classnames';
import styles from './index.css';

interface AvatarProps {
    src?: string;
    type: 'circle' | 'round-corner';
    style?: any;
}

export const Avatar = ({ src, type, style = {} }: AvatarProps) => {
    const className = classnames(
        styles.avatar,
        type === 'circle' && styles.circle,
        type === 'round-corner' && styles.roundedCorner
    );

    return (
        <div
            className={className}
            style={{
                backgroundImage: src ? `url(${src})` : undefined,
                ...style
            }}
        />
    );
};
