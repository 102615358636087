import { h, Component } from 'preact';
import { classnames } from '../../utils/classnames';
import styles from './index.css';

interface ButtonProps {
    primary?: boolean;
}

export class Button extends Component<ButtonProps> {
    public render() {
        const className = classnames(
            styles.button,
            this.props.primary && styles.primary
        );

        return (
            <button type="button" className={className}>
                {this.props.children}
            </button>
        );
    }
}
