import { h, Component } from 'preact';
import * as Portal from 'preact-portal';
import { classnames } from '../../utils/classnames';
import { compatibility } from '../compatibility';
import * as closeX from './assets/close.svg';
import styles from './index.css';
import { onViewState } from '../../config';
import { i18n } from '../../i18n';

interface ModalProps {
    open: boolean;
    children: any;
    top?: number;
    onDismiss(): void;
    onHeight?(height: number): void;
}

interface ModalState {
    mobile?: boolean;
}

const Backdrop = compatibility('div');
const CloseButton = compatibility('button');

export class Modal extends Component<ModalProps, ModalState> {
    private viewStateDisposer?: () => void;

    constructor(props: ModalProps) {
        super(props);

        this.onInnerEl = this.onInnerEl.bind(this);
        this.onKeyUp = this.onKeyUp.bind(this);

        this.state = {};
    }

    public componentDidMount() {
        this.viewStateDisposer = onViewState(viewState =>
            this.setState({ mobile: viewState === 'mobile' })
        );
    }

    public componentWillUnmount() {
        if (this.viewStateDisposer) {
            this.viewStateDisposer();
        }
    }

    public componentDidUpdate(prevProps: ModalProps) {
        if (!prevProps.open && this.props.open) {
            document.addEventListener('keyup', this.onKeyUp);
        }

        if (prevProps.open && !this.props.open) {
            document.removeEventListener('keyup', this.onKeyUp);
        }
    }

    public render() {
        const { open, children, onDismiss, top = 0 } = this.props;
        const className = classnames(styles.modal, open && styles.open);
        const innerClassName = classnames(
            styles.inner,
            this.state.mobile && styles.innerMobile
        );

        return (
            <Portal into="body">
                <div className={className}>
                    <Backdrop className={styles.backdrop} onClick={onDismiss} />
                    {open ? (
                        <div
                            className={innerClassName}
                            ref={this.onInnerEl}
                            style={{ top }}
                        >
                            {children}
                            <CloseButton
                                className={styles.close}
                                onClick={onDismiss}
                            >
                                <span className={styles.closeText}>
                                    {i18n.__('commons.close')}
                                </span>
                                <span className={styles.closeX}>
                                    <img
                                        className={styles.closeImg}
                                        src={closeX}
                                    />
                                </span>
                            </CloseButton>
                        </div>
                    ) : null}
                </div>
            </Portal>
        );
    }

    private onInnerEl(el?: HTMLDivElement) {
        const { onHeight } = this.props;

        if (el && onHeight) {
            let height = 0;
            let tries = 0;

            const int = setInterval(() => {
                const rect = el!.getBoundingClientRect();

                if (rect.height && onHeight) {
                    onHeight(rect.height);
                }

                if (height === rect.height && tries >= 10) {
                    clearInterval(int);
                }

                height = rect.height;
                tries++;
            }, 16);
        }
    }

    private onKeyUp(e: KeyboardEvent) {
        // ESC
        if (e.keyCode === 27) {
            this.props.onDismiss();
        }
    }
}
