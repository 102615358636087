import { h, Component, ComponentChildren } from 'preact';
import { deviceClick } from './device';

interface CompatibilityProps<T = {}> extends preact.ClassAttributes<T> {
    className?: string;
    style?: any;
    onClick?(e?: any): void;
}

interface CompatibilityWrapperProps {
    nodeName: string;
    componentProps: CompatibilityProps;
}

class CompatibilityWrapper extends Component<CompatibilityWrapperProps> {
    private clickDisposer?: () => void;

    constructor() {
        super();

        this.onRef = this.onRef.bind(this);
    }

    public componentWillUnmount() {
        if (this.clickDisposer) {
            this.clickDisposer();
        }
    }

    public render() {
        const { onClick, ...props } = this.props.componentProps;

        return h(this.props.nodeName, {
            ...props,
            ref: this.onRef
        });
    }

    private onRef(el?: HTMLElement) {
        const { onClick } = this.props.componentProps;

        if (el && onClick) {
            this.clickDisposer = deviceClick(el, onClick);
        }
    }
}

export function compatibility<T = { children?: ComponentChildren }>(
    nodeName: CompatibilityWrapperProps['nodeName']
) {
    return (props: CompatibilityProps & T) => {
        return (
            <CompatibilityWrapper nodeName={nodeName} componentProps={props} />
        );
    };
}
