import * as colors from './color.json';

export type ColorType =
    | 'white'
    | 'black'
    | 'coolgrey'
    | 'palegrey'
    | 'lightgrey'
    | 'sky'
    | 'sky-light'
    | 'sky-dark'
    | 'greenish'
    | 'greenish-dark'
    | 'greenish-light'
    | 'grapefruit'
    | 'grapefruit-light'
    | 'grapefruit-dark'
    | 'facebook'
    | 'facebook-light'
    | 'facebook-dark'
    | 'transparent';

export function getColor(val: ColorType): string {
    return colors[val.toUpperCase()];
}
