import { h } from 'preact';
import styles from './index.css';

export const CalendarItem = (props: {
    month: string;
    day: number;
    style?: any;
}) => (
    <div className={styles.calendarItem} style={props.style}>
        <div className={styles.month}>{props.month}</div>
        <div className={styles.day}>{props.day}</div>
    </div>
);
