import { h, Component } from 'preact';
import { classnames } from '../../utils/classnames';
import { ArrowDown } from '../arrow';
import { compatibility } from '../compatibility';
import { Layout } from '../layout';
import { Text } from '../text';
import styles from './index.css';

export interface SelectOption<T = any> {
    label: string;
    value: T;
}

interface SelectProps<T = any> {
    value?: T;
    placeholder?: string;
    options: SelectOption[];
    onChange(value?: T): void;
}

interface SelectState {
    open?: boolean;
}

const Placeholder = compatibility('div');
const Option = compatibility('div');

export class Select extends Component<SelectProps, SelectState> {
    constructor(props: SelectProps) {
        super(props);

        this.state = {
            open: false
        };

        this.onChange = this.onChange.bind(this);
        this.onToggle = this.onToggle.bind(this);
    }

    public render() {
        const { open } = this.state;
        const { options, value, placeholder } = this.props;
        const selectedOption = options.find(option => option.value === value);
        const valueText = selectedOption ? selectedOption.label : placeholder;

        return (
            <div className={styles.select}>
                <Layout
                    vAlign="center"
                    hAlign="space-between"
                    className={styles.valueContainer}
                    onClick={this.onToggle}
                >
                    <Text size="S" className={styles.valueContainerText}>
                        {valueText}
                    </Text>
                    <ArrowDown />
                </Layout>
                {open && (
                    <div className={styles.optionsContainer}>
                        {placeholder && (
                            <Placeholder
                                className={this.getOptionClassName()}
                                data-index={-1}
                                onClick={this.onChange}
                            >
                                <Text size="S">{placeholder}</Text>
                            </Placeholder>
                        )}
                        {options.map((option, i) => (
                            <Option
                                key={option.value}
                                data-index={i}
                                className={this.getOptionClassName(option)}
                                onClick={this.onChange}
                            >
                                <Text size="S">{option.label}</Text>
                            </Option>
                        ))}
                    </div>
                )}
                {this.renderSelect()}
            </div>
        );
    }

    private renderSelect() {
        const { options, placeholder, value } = this.props;

        return (
            <select
                value={value}
                onChange={this.onChange}
                className={styles.hide}
            >
                <option value="">{placeholder}</option>
                {options.map((option, i) => (
                    <option
                        key={option.value}
                        data-index={i}
                        value={option.value}
                    >
                        {option.label}
                    </option>
                ))}
            </select>
        );
    }

    private getOptionClassName(option?: SelectOption) {
        const { value } = this.props;
        const hasValue = option && option.value === value;
        const noValue = !value && !option;

        return classnames(
            styles.option,
            (hasValue || noValue) && styles.optionSelected
        );
    }

    private onToggle() {
        this.setState({ open: !this.state.open });
    }

    private onChange(e: Event) {
        const target = e.currentTarget as HTMLElement;
        const index = parseInt(
            target.getAttribute('data-index') ||
                target.querySelector(':checked')!.getAttribute('data-index')!,
            10
        );
        const option = this.props.options[index];

        this.props.onChange(option ? option.value : undefined);
        this.setState({ open: false });
    }
}
