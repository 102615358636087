import { h, Component, ComponentChild, cloneElement } from 'preact';
import { classnames } from '../../utils/classnames';
import { compatibility } from '../compatibility';
import { SpaceSize, getSpace } from '../vars/space';
import styles from './index.css';

type LayoutVAlign = 'center' | 'space-between' | 'flex-end';
type LayoutHAlign = 'center' | 'space-between' | 'flex-end';

interface LayoutProps {
    className?: string;
    padding?: SpaceSize | SpaceSize[];
    between?: SpaceSize;
    stack?: boolean;
    vAlign?: LayoutVAlign;
    hAlign?: LayoutHAlign;
    stretch?: boolean;
    children: ComponentChild | ComponentChild[];
    style?: any;
    onClick?(e: any): void;
}

function getPaddingCssValue({ padding }: LayoutProps): undefined | string {
    if (Array.isArray(padding)) {
        return padding.map(getSpace).join(' ');
    } else if (padding || padding === 0) {
        return getSpace(padding);
    }

    return undefined;
}

function getBetweenCssProperty({ stack }: LayoutProps): string {
    return stack ? 'margin-bottom' : 'margin-right';
}

function getBeweenCssValue({ between }: LayoutProps): string {
    return getSpace(between);
}

function getHAlign({ hAlign, stack }: LayoutProps): string | undefined {
    if (stack) {
        switch (hAlign) {
            case 'center':
                return styles.alignCenter;
            case 'space-between':
                return styles.alignSpaceBetween;
            case 'flex-end':
                return styles.alignFlexEnd;
        }
    } else {
        switch (hAlign) {
            case 'center':
                return styles.justifyCenter;
            case 'space-between':
                return styles.justifySpaceBetween;
            case 'flex-end':
                return styles.justifyFlexEnd;
        }
    }

    return undefined;
}

function getVAlign({ vAlign, stack }: LayoutProps): string | undefined {
    if (stack) {
        switch (vAlign) {
            case 'center':
                return styles.justifyCenter;
            case 'space-between':
                return styles.justifySpaceBetween;
            case 'flex-end':
                return styles.justifyFlexEnd;
        }
    } else {
        switch (vAlign) {
            case 'center':
                return styles.alignCenter;
            case 'space-between':
                return styles.alignSpaceBetween;
            case 'flex-end':
                return styles.alignFlexEnd;
        }
    }

    return undefined;
}

const Container = compatibility('div');

export class Layout extends Component<LayoutProps> {
    private get className() {
        const { vAlign, hAlign, stack, stretch } = this.props;

        return classnames(
            styles.layout,
            this.props.className,
            stack && styles.stack,
            stretch && styles.stretch,
            hAlign && getHAlign(this.props),
            vAlign && getVAlign(this.props)
        );
    }

    public render(): JSX.Element {
        const padding = getPaddingCssValue(this.props);
        const { style = {} } = this.props;

        if (padding) {
            // NOTE: adding `padding: undefined` will cause preact to render wrong values
            style.padding = padding;
        }

        return (
            <Container
                className={this.className}
                style={style}
                onClick={this.props.onClick}
            >
                {this.renderChildren()}
            </Container>
        );
    }

    private renderChildren() {
        const { children, between } = this.props;

        if (!between) {
            return children;
        }

        if (Array.isArray(children)) {
            const filteredChildren = children.filter(child => Boolean(child));

            return filteredChildren.map((child, i) => {
                const last = i === filteredChildren.length - 1;
                const style = last
                    ? undefined
                    : {
                          // prettier-ignore
                          [getBetweenCssProperty(this.props)]: getBeweenCssValue(this.props)
                      };

                if (
                    style &&
                    child &&
                    typeof child !== 'string' &&
                    typeof child !== 'number'
                ) {
                    const childProps =
                        (child.attributes && child.attributes.style) || {};

                    return cloneElement(child, {
                        style: { ...childProps, ...style }
                    });
                }

                return child;
            });
        }

        return children;
    }
}
