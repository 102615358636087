import { LocalDate } from '@spa-frontend/date-lib';
import { Component, ComponentChildren, h } from 'preact';
import { ArrowLeft, ArrowRight } from '../components/arrow';
import { compatibility } from '../components/compatibility';
import { Layout } from '../components/layout';
import { Text } from '../components/text';
import { i18n } from '../i18n';
import { classnames } from '../utils/classnames';
import styles from './header.css';

interface CoursesHeaderProps {
    now: LocalDate;
    dates: LocalDate[];
    mobile?: boolean;
    loading?: boolean;
    style?: any;
    onNext(): void;
    onPrev(): void;
}

const PrevButton = compatibility<{
    disabled?: boolean;
    children?: ComponentChildren;
}>('button');
const NextButton = compatibility<{
    disabled?: boolean;
    children?: ComponentChildren;
}>('button');

export class CoursesHeader extends Component<CoursesHeaderProps> {
    public render() {
        const { dates, mobile, style } = this.props;
        const weekdayFormatter = new Intl.DateTimeFormat(i18n.currentLocale, {
            weekday: 'long'
        });
        const dateFormatter = new Intl.DateTimeFormat(i18n.currentLocale, {
            day: 'numeric',
            month: 'long',
            year: 'numeric'
        });

        return (
            <div className={styles.container} style={style}>
                {this.renderPrevButton()}
                <Layout
                    between="M"
                    padding={[0, 'L']}
                    className={styles.layout}
                >
                    {dates.map(date => (
                        <Layout
                            key={`header-${date.toString()}`}
                            stack
                            between="XS"
                            hAlign={mobile ? 'center' : undefined}
                            padding={['M', 0, 'M', 'S']}
                            className={this.getColumnClass(date)}
                        >
                            <Text size="S" bold>
                                {weekdayFormatter.format(date.toDate())}
                            </Text>
                            <Text>{dateFormatter.format(date.toDate())}</Text>
                        </Layout>
                    ))}
                </Layout>
                {this.renderNextButton()}
            </div>
        );
    }

    private getColumnClass(date: LocalDate) {
        const past = date.isBefore(this.props.now);
        const today = date.isSameDay(this.props.now);

        return classnames(
            styles.column,
            past && styles.past,
            today && styles.active
        );
    }

    private renderPrevButton() {
        const { dates, loading, now } = this.props;
        const hidden = dates.some(date => date.isBefore(now));
        const className = classnames(styles.buttonPrev, hidden && styles.hide);

        return (
            <PrevButton
                disabled={loading}
                className={className}
                onClick={this.props.onPrev}
            >
                <ArrowLeft />
            </PrevButton>
        );
    }

    private renderNextButton() {
        const { loading } = this.props;

        return (
            <NextButton
                disabled={loading}
                className={styles.buttonNext}
                onClick={this.props.onNext}
            >
                <ArrowRight />
            </NextButton>
        );
    }
}
