import { h, render } from 'preact';
import { App } from './app';
import { config } from './config';
import './fonts.css';

const interval = setInterval(() => {
    if (config.ready) {
        clearInterval(interval);

        render(<App />, document.getElementById('app')!);
    }
}, 100);
