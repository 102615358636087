import { h, Component } from 'preact';
import styles from './index.css';

interface ProgressProps {
    style?: any;
    current: number;
    max: number;
}

export class Progress extends Component<ProgressProps> {
    public render() {
        const { current, max, style = {} } = this.props;
        const p = Math.round((current / max) * 100);
        const width = `${p}%`;

        return (
            <div className={styles.bg} style={style}>
                <div className={styles.bar} style={{ width }} />
            </div>
        );
    }
}
