import * as apiStudios from './studio-client';

const parseNull = (item: any): any => {
    if (item === null) {
        return undefined;
    }

    if (Array.isArray(item)) {
        return item.map(parseNull);
    }

    if (typeof item === 'object') {
        return Object.keys(item).reduce(
            (prev: {}, key: string) => ({
                ...prev,
                [key]: parseNull(item[key])
            }),
            {}
        );
    }

    return item;
};

const parseError = (error: any) => {
    if (Array.isArray(error)) {
        return error[0].errorCode;
    }

    return error.errorCode;
};

export function getClient(endpoint: string) {
    return apiStudios.getClient(endpoint, undefined, pretend =>
        pretend.decode(async response => {
            const json = await response.json();

            if (response.status === 200) {
                return parseNull(json);
            }

            throw new Error(parseError(json));
        })
    );
}
