import { getClient as internalGetClient, NoExcuse } from './client';
import { Pretend } from 'pretend';

export function getClient(
    endpoint: string,
    headers: { [key: string]: string } = {},
    configure?: (client: Pretend) => Pretend,
    debug = false
): NoExcuse {
    return internalGetClient(endpoint, undefined, pretend => {
        if (debug) {
            pretend.interceptor(async (chain, request) => {
                // tslint:disable-next-line:no-console
                console.log(
                    `Request: ${request.options.method} ${request.url}`,
                    JSON.stringify(request.options.body)
                );
                const response = await chain(request);
                // tslint:disable-next-line:no-console
                console.log(
                    `Response: ${request.options.method} ${request.url}`,
                    JSON.stringify(response)
                );
                return response;
            });
        }

        // remove undefined query-parameters
        pretend.interceptor(async (chain, request) => {
            request.url = request.url.replace(/([?&]?\w+=undefined)/g, '');

            return await chain(request);
        });

        pretend.requestInterceptor(request => {
            const internalHeaders = new Headers(request.options.headers);

            Object.keys(headers).forEach(key =>
                internalHeaders.set(key, headers[key])
            );

            if (!internalHeaders.has('Content-Type')) {
                internalHeaders.set('Content-Type', 'application/json');
            } else if (
                internalHeaders.get('Content-Type') === 'multipart/form-data'
            ) {
                // note: strange but true, the fetch API sets the
                // content-type header on its own
                internalHeaders.delete('Content-Type');
            }

            request.options.headers = internalHeaders;

            return request;
        });

        if (configure) {
            return configure(pretend);
        }

        return pretend;
    });
}
