import { Component, h } from 'preact';
import { StudioExtendedDto } from '../api/dtos';
import { Layout } from '../components/layout';
import { Select } from '../components/select';
import { i18n } from '../i18n';
import styles from './filter.css';

interface CoursesFilterProps {
    style?: any;
    studios: StudioExtendedDto[];
    organizationUnitId?: number;
    onChangeStudio(id?: number): void;
}

export class CoursesFilter extends Component<CoursesFilterProps> {
    constructor(props: CoursesFilterProps) {
        super(props);

        this.state = {
            studios: []
        };
    }

    public render() {
        return (
            <Layout hAlign="center" className={styles.filter}>
                <Select
                    placeholder={i18n.__('noxweb.select.studios.all')}
                    value={this.props.organizationUnitId}
                    onChange={this.props.onChangeStudio}
                    options={this.props.studios.map(studio => ({
                        label: studio.name,
                        value: studio.id
                    }))}
                />
            </Layout>
        );
    }
}
