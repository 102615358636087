import { h, Component } from 'preact';
import { Layout } from '../components/layout';
import { postMessage } from '../config';
import { Courses } from '../courses';
import styles from './index.css';

export class App extends Component {
    public render() {
        return (
            <Layout stack className={styles.container}>
                <Courses onContentHeight={this.onCoursesContentHeight} />
            </Layout>
        );
    }

    private onCoursesContentHeight(height: number) {
        postMessage(String(height), 'ms-nox-message-change-height');
    }
}
