import { Locale } from 'date-fns';

type Manifest = { path: string; hashedPath: string }[];

export type TranslateFn = (
    key?: I18nKey,
    // tslint:disable-next-line:no-any
    ...params: any[]
) => string;

const LOCALE_WHITELIST = ['de-DE', 'en-GB', 'es-ES', 'fr-FR', 'it-IT', 'nl-NL'];
const LOCALE_FALLBACK = 'en-GB';

function extractLang(locale: string): string {
    return locale.split('-')[0];
}

function getLocaleFallback(lang: string) {
    switch (lang) {
        case 'ca':
        case 'ca-ES':
        case 'ca-AD':
            return 'es-ES';
        case 'ca-FR':
            return 'fr-FR';
        case 'ca-IT':
            return 'it-IT';
        default:
        case 'en':
            return LOCALE_FALLBACK;
    }
}

function getDateFnsLocale(lang: string): Locale {
    switch (lang) {
        case 'de':
            return require('date-fns/locale/de');
        case 'it':
            return require('date-fns/locale/it');
        case 'es':
            return require('date-fns/locale/es');
        case 'fr':
            return require('date-fns/locale/fr');
        case 'nl':
            return require('date-fns/locale/nl');
        default:
            return require('date-fns/locale/en-GB');
    }
}

class I18n {
    public __: TranslateFn = () => '';

    private manifest?: Manifest;

    private ready = false;

    public get currentLocale() {
        const { language } = navigator;
        const locale = language || '';

        if (
            LOCALE_WHITELIST.some(
                loc => extractLang(loc) === extractLang(locale)
            )
        ) {
            return locale;
        }

        return getLocaleFallback(locale);
    }

    public get currentLanguage(): string {
        return extractLang(this.currentLocale).toLowerCase();
    }

    public get dateFnsLocale() {
        return getDateFnsLocale(this.currentLanguage);
    }

    constructor() {
        this.ready = false;

        (async () => {
            await this.loadManifest();
            await this.loadTranslationBundle(this.currentLanguage);

            this.ready = true;
        })();
    }

    public async waitFor() {
        let tries = 0;

        return new Promise<void>((resolve, reject) => {
            const int = setInterval(() => {
                tries++;

                if (tries >= 50) {
                    reject();
                } else if (this.ready) {
                    clearInterval(int);
                    resolve();
                }
            }, 100);
        });
    }

    private async loadManifest(): Promise<void> {
        const data = await fetch('/assets/localizations/manifest.json', {
            headers: new Headers({
                'cache-control': 'no-cache'
            })
        });
        this.manifest = (await data.json()) as Manifest;
    }

    private async loadTranslationBundle(localeParam: string) {
        if (typeof localeParam !== 'string' || !this.manifest) {
            return;
        }
        const loc = localeParam.replace(/"/g, '');
        const lang =
            LOCALE_WHITELIST.find(l => extractLang(l) === loc) ||
            LOCALE_FALLBACK;
        const localeFile = `/${lang}.json`;

        const entry = this.manifest.find(
            entry => entry.path === localeFile
        ) || { hashedPath: localeFile };

        const sourceArray: any[] = await (
            await fetch(`/assets/localizations${entry.hashedPath}`)
        ).json();

        const source = sourceArray.reduce<{ [key in I18nKey]: string }>(
            (mem, { key, value }: { key: I18nKey; value: string }) => (
                (mem[key] = value), mem
            ),
            {} as { [key in I18nKey]: string }
        );

        this.__ = this.wrap((key?: I18nKey, params?: any) => {
            const res = key ? source[key] : null;

            return res && params
                ? res
                      .replace(/\${/g, '___dollar___bracket___')
                      .replace(/{\w}/g, v => params[v[1]])
                      .replace(/___dollar___bracket___/g, '${')
                : res || '';
        });
    }

    private wrap(fn: TranslateFn): TranslateFn {
        const convertParams = (params: any[]) => {
            // note: allowed values for params are
            // [ 'value1', 'value2', 'value3' ] and
            // [ { a: 'value1', b: 'value2', c: 'value3' } ]
            // params as string-array will be transformed to the latter syntax.
            if (params[0] && typeof params[0] !== 'object') {
                params = [
                    params.reduce(
                        (
                            memo: { [key: string]: string | number },
                            param: string | number,
                            i: number
                        ) => {
                            memo[String.fromCharCode(97 + i)] = param;

                            return memo;
                        },
                        {}
                    )
                ];
            }
            return params;
        };

        return (key, ...params) => {
            if (key === null || key === undefined || key === '') {
                return '';
            }

            try {
                const value = fn(key, ...convertParams(params));
                if (key === value) {
                    console.warn(
                        `%ci18n missing: %c${value}`,
                        'color:#94999d',
                        'color:#000000'
                    );
                }
                return value;
            } catch (e) {
                if (e instanceof Error) {
                    console.warn(e.message);
                }
                return '';
            }
        };
    }
}

export const i18n = new I18n();
