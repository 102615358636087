import { h, Component } from 'preact';
import { classnames } from '../../utils/classnames';
import { ColorType } from '../vars/color';
import colors from '../vars/color.css';
import { TextSize } from '../vars/text-size';
import sizes from '../vars/text-size.css';
import styles from './index.css';

interface TextProps {
    className?: string;
    color?: ColorType;
    size?: TextSize;
    bold?: boolean;
    ellipsis?: boolean;
    children?: preact.ComponentChildren;
    style?: any;
}

export class Text extends Component<TextProps> {
    private get className() {
        const { bold, ellipsis, color = 'black', size } = this.props;

        return classnames(
            styles.text,
            this.props.className,
            bold && styles.bold,
            ellipsis && styles.ellipsis,
            color && colors[color],
            size && sizes[size]
        );
    }

    public render() {
        const { children, style = {} } = this.props;

        return (
            <span className={this.className} style={style}>
                {children}
            </span>
        );
    }
}
