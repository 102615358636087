import { Pretend, IPretendRequest, Get } from 'pretend';

import * as dtos from './dtos';
import {
    LocalDate,
    ZonedDateTime,
    parseApiDates
} from '@spa-frontend/date-lib';

const parseResult = (props: string[]) => {
    return (
        _target: Object,
        _key: string | symbol,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        descriptor: TypedPropertyDescriptor<any>
    ) => {
        const fn = descriptor.value;

        descriptor.value = async function(...args: unknown[]) {
            const result = await fn.apply(this, args);

            const tupleProps = props.map(prop => prop.split('.'));

            return parseApiDates(result, tupleProps);
        };

        return descriptor;
    };
};

export interface NoxBookableItemPublicController {
    getBookableCourseItem(itemId: number): Promise<dtos.BookableItemDto>;

    getBookableSingleItem(
        benefitId: number,
        query: {
            employeeIds: number[];
            organizationUnitId: number;
            startDateTime: ZonedDateTime;
        }
    ): Promise<dtos.BookableItemDto>;

    getCoursePlan(query: {
        benefitCategoryIds?: number[];
        benefitIds?: number[];
        courseAvailability?:
            | 'ALL'
            | 'BOOKABLE'
            | 'BOOKABLE_OR_WITH_WAITING_LIST';
        employeeIds?: number[];
        endDate?: LocalDate;
        isStudioEvent?: boolean;
        maxResults?: number;
        organizationUnitIds?: number[];
        search?: string;
        startDate: LocalDate;
    }): Promise<dtos.BookableItemDto[]>;

    getCoursePlanWithDefaultHomeStudio(query: {
        benefitCategoryIds?: number[];
        benefitIds?: number[];
        courseAvailability?:
            | 'ALL'
            | 'BOOKABLE'
            | 'BOOKABLE_OR_WITH_WAITING_LIST';
        employeeIds?: number[];
        endDate?: LocalDate;
        isStudioEvent?: boolean;
        maxResults?: number;
        organizationUnitIds?: number[];
        search?: string;
        startDate: LocalDate;
    }): Promise<dtos.BookableItemDto[]>;

    getForTrialSession(
        benefitId: number,
        query: {
            endDate: LocalDate;
            slotLimit?: string;
            startDate: LocalDate;
            timePeriodIds?: number[];
            timePeriods?: string[];
        }
    ): Promise<dtos.BookableBenefitItemDto>;

    getNextAvailableCoursePlan(query: {
        benefitCategoryIds?: number[];
        benefitIds?: number[];
        courseAvailability?:
            | 'ALL'
            | 'BOOKABLE'
            | 'BOOKABLE_OR_WITH_WAITING_LIST';
        employeeIds?: number[];
        isStudioEvent?: boolean;
        maxResults?: number;
        organizationUnitIds?: number[];
        search?: string;
        startDate: LocalDate;
    }): Promise<dtos.NextAvailableBookableItemsDto>;

    getSingleBenefit(
        benefitId: number,
        query: {
            employeeIds?: number[];
            endDate: LocalDate;
            organizationUnitIds?: number[];
            slotLimit?: string;
            startDate: LocalDate;
            timePeriodIds?: number[];
            timePeriods?: string[];
        }
    ): Promise<dtos.BookableBenefitItemDto>;

    getSingleBenefits(query: {
        benefitCategoryIds?: number[];
        employeeIds?: number[];
        organizationUnitIds?: number[];
        search?: string;
        timePeriodIds?: number[];
        timePeriods?: string[];
    }): Promise<dtos.BookableBenefitItemDto[]>;

    getUpcomingCourses(query: {
        organizationUnitId: number;
    }): Promise<dtos.BookableItemDto[]>;
}

export interface NoxBookableItemPublicControllerV2 {
    getCourseAppointmentsByBenefit(query: {
        benefitId: number;
        end?: ZonedDateTime;
        organizationUnitId: number;
        start?: ZonedDateTime;
    }): Promise<dtos.BookableItemDto[]>;

    getCourseAppointmentsSliceByBenefit(query: {
        benefitId: number;
        maxResults?: number;
        offset?: number;
        organizationUnitId: number;
        start?: ZonedDateTime;
    }): Promise<dtos.SliceResultOfBookableItemDto>;

    getCourseBenefitsWithUpcomingAppointment(query: {
        endDateTime?: ZonedDateTime;
        organizationUnitIds: number[];
        startDateTime?: ZonedDateTime;
    }): Promise<dtos.BookableBenefitItemDto[]>;

    getCoursePlanWithCanceledCourses(query: {
        benefitCategoryIds?: number[];
        benefitIds?: number[];
        courseAvailability?:
            | 'ALL'
            | 'BOOKABLE'
            | 'BOOKABLE_OR_WITH_WAITING_LIST';
        employeeIds?: number[];
        endDate?: LocalDate;
        isStudioEvent?: boolean;
        maxResults?: number;
        organizationUnitIds?: number[];
        search?: string;
        startDate: LocalDate;
    }): Promise<dtos.BookableItemDto[]>;

    getNextAvailableSingleBenefit(
        benefitId: number,
        query: {
            employeeIds?: number[];
            maxDaysLookup?: number;
            organizationUnitIds?: number[];
            slotLimit?: string;
            startDateTime: ZonedDateTime;
            timePeriodIds?: number[];
            timePeriods?: string[];
        }
    ): Promise<dtos.NextAvailableBookableBenefitItemDto>;

    getSingleBenefit(
        benefitId: number,
        query: {
            employeeIds?: number[];
            endDateTime: ZonedDateTime;
            organizationUnitIds?: number[];
            slotLimit?: string;
            startDateTime: ZonedDateTime;
            timePeriodIds?: number[];
            timePeriods?: string[];
        }
    ): Promise<dtos.BookableBenefitItemDto>;

    getStudioEventCourseBenefits(query: {
        organizationUnitIds: number[];
    }): Promise<dtos.BookableBenefitItemDto[]>;
}

export interface NoxStudioController {
    // tslint:disable:no-any
    getAnyStudioLogo(): Promise<any>;
    // tslint:enable:no-any

    getHomeStudio(): Promise<dtos.StudioExtendedDto>;

    getRevealUtilization(
        studioId: number
    ): Promise<dtos.WrappedResultOfboolean>;

    getRevealUtilizationPublic(
        studioId: number
    ): Promise<dtos.WrappedResultOfboolean>;

    getSepaAgreementText(studioId: number): Promise<string>;

    // tslint:disable:no-any
    getStudioLogo(studioId: number): Promise<any>;
    // tslint:enable:no-any

    getStudioPublic(studioId: number): Promise<dtos.StudioExtendedDto>;

    getStudiosPublic(): Promise<dtos.StudioExtendedDto[]>;

    getUtilization(studioId: number): Promise<dtos.UtilizationDto>;

    getUtilizationPublic(studioId: number): Promise<dtos.UtilizationDto>;
}

export interface NoExcuse {
    NoxBookableItemPublicController: NoxBookableItemPublicController;
    NoxBookableItemPublicControllerV2: NoxBookableItemPublicControllerV2;
    NoxStudioController: NoxStudioController;
}

// tslint:disable:no-any

class NoxBookableItemPublicControllerBlueprint
    implements NoxBookableItemPublicController {
    @parseResult([
        'slots.earliestBookingDateTime',
        'slots.endDateTime',
        'slots.latestBookingDateTime',
        'slots.startDateTime'
    ])
    @Get('/nox/public/v1/bookableitems/course/{itemId}')
    public getBookableCourseItem(_p0: any): any {
        /**/
    }

    @parseResult([
        'slots.earliestBookingDateTime',
        'slots.endDateTime',
        'slots.latestBookingDateTime',
        'slots.startDateTime'
    ])
    @Get('/nox/public/v1/bookableitems/single/{benefitId}', true)
    public getBookableSingleItem(_p0: any, _p1: any): any {
        /**/
    }

    @parseResult([
        'slots.earliestBookingDateTime',
        'slots.endDateTime',
        'slots.latestBookingDateTime',
        'slots.startDateTime'
    ])
    @Get('/nox/public/v1/bookableitems/course', true)
    public getCoursePlan(_p0: any): any {
        /**/
    }

    @parseResult([
        'slots.earliestBookingDateTime',
        'slots.endDateTime',
        'slots.latestBookingDateTime',
        'slots.startDateTime'
    ])
    @Get('/nox/public/v1/bookableitems/course/withdefaulthomestudio', true)
    public getCoursePlanWithDefaultHomeStudio(_p0: any): any {
        /**/
    }

    @parseResult([
        'slots.earliestBookingDateTime',
        'slots.endDateTime',
        'slots.latestBookingDateTime',
        'slots.startDateTime',
        'upcomingAppointmentDateTime'
    ])
    @Get('/nox/public/v1/bookableitems/benefit/{benefitId}/trialsession', true)
    public getForTrialSession(_p0: any, _p1: any): any {
        /**/
    }

    @parseResult([
        'bookableItems.slots.earliestBookingDateTime',
        'bookableItems.slots.endDateTime',
        'bookableItems.slots.latestBookingDateTime',
        'bookableItems.slots.startDateTime',
        'date'
    ])
    @Get('/nox/public/v1/bookableitems/course/nextavailable', true)
    public getNextAvailableCoursePlan(_p0: any): any {
        /**/
    }

    @parseResult([
        'slots.earliestBookingDateTime',
        'slots.endDateTime',
        'slots.latestBookingDateTime',
        'slots.startDateTime',
        'upcomingAppointmentDateTime'
    ])
    @Get('/nox/public/v1/bookableitems/benefit/{benefitId}', true)
    public getSingleBenefit(_p0: any, _p1: any): any {
        /**/
    }

    @parseResult([
        'slots.earliestBookingDateTime',
        'slots.endDateTime',
        'slots.latestBookingDateTime',
        'slots.startDateTime',
        'upcomingAppointmentDateTime'
    ])
    @Get('/nox/public/v1/bookableitems/benefits', true)
    public getSingleBenefits(_p0: any): any {
        /**/
    }

    @parseResult([
        'slots.earliestBookingDateTime',
        'slots.endDateTime',
        'slots.latestBookingDateTime',
        'slots.startDateTime'
    ])
    @Get('/nox/public/v1/bookableitems/course/upcoming', true)
    public getUpcomingCourses(_p0: any): any {
        /**/
    }
}

class NoxBookableItemPublicControllerV2Blueprint
    implements NoxBookableItemPublicControllerV2 {
    @parseResult([
        'slots.earliestBookingDateTime',
        'slots.endDateTime',
        'slots.latestBookingDateTime',
        'slots.startDateTime'
    ])
    @Get('/nox/public/v2/bookableitems/courses', true)
    public getCourseAppointmentsByBenefit(_p0: any): any {
        /**/
    }

    @parseResult([
        'content.slots.earliestBookingDateTime',
        'content.slots.endDateTime',
        'content.slots.latestBookingDateTime',
        'content.slots.startDateTime'
    ])
    @Get('/nox/public/v2/bookableitems/courses/slice', true)
    public getCourseAppointmentsSliceByBenefit(_p0: any): any {
        /**/
    }

    @parseResult([
        'slots.earliestBookingDateTime',
        'slots.endDateTime',
        'slots.latestBookingDateTime',
        'slots.startDateTime',
        'upcomingAppointmentDateTime'
    ])
    @Get('/nox/public/v2/bookableitems/courses/with-upcoming-appointment', true)
    public getCourseBenefitsWithUpcomingAppointment(_p0: any): any {
        /**/
    }

    @parseResult([
        'slots.earliestBookingDateTime',
        'slots.endDateTime',
        'slots.latestBookingDateTime',
        'slots.startDateTime'
    ])
    @Get('/nox/public/v2/bookableitems/courses/with-canceled', true)
    public getCoursePlanWithCanceledCourses(_p0: any): any {
        /**/
    }

    @parseResult([
        'bookableItem.slots.earliestBookingDateTime',
        'bookableItem.slots.endDateTime',
        'bookableItem.slots.latestBookingDateTime',
        'bookableItem.slots.startDateTime',
        'bookableItem.upcomingAppointmentDateTime',
        'date'
    ])
    @Get('/nox/public/v2/bookableitems/benefit/{benefitId}/nextavailable', true)
    public getNextAvailableSingleBenefit(_p0: any, _p1: any): any {
        /**/
    }

    @parseResult([
        'slots.earliestBookingDateTime',
        'slots.endDateTime',
        'slots.latestBookingDateTime',
        'slots.startDateTime',
        'upcomingAppointmentDateTime'
    ])
    @Get('/nox/public/v2/bookableitems/benefit/{benefitId}', true)
    public getSingleBenefit(_p0: any, _p1: any): any {
        /**/
    }

    @parseResult([
        'slots.earliestBookingDateTime',
        'slots.endDateTime',
        'slots.latestBookingDateTime',
        'slots.startDateTime',
        'upcomingAppointmentDateTime'
    ])
    @Get('/nox/public/v2/bookableitems/benefit/studio-events', true)
    public getStudioEventCourseBenefits(_p0: any): any {
        /**/
    }
}

class NoxStudioControllerBlueprint implements NoxStudioController {
    @Get('/nox/public/v1/studios/any/logo')
    public getAnyStudioLogo(): any {
        /**/
    }

    @parseResult(['closingHoursDtos.from', 'closingHoursDtos.until'])
    @Get('/nox/v1/studios/home')
    public getHomeStudio(): any {
        /**/
    }

    @Get('/nox/v1/studios/{studioId}/utilization/reveal')
    public getRevealUtilization(_p0: any): any {
        /**/
    }

    @Get('/nox/public/v1/studios/{studioId}/utilization/reveal')
    public getRevealUtilizationPublic(_p0: any): any {
        /**/
    }

    @Get('/nox/public/v1/studios/{studioId}/sepa/agreement')
    public getSepaAgreementText(_p0: any): any {
        /**/
    }

    @Get('/nox/public/v1/studios/{studioId}/logo')
    public getStudioLogo(_p0: any): any {
        /**/
    }

    @parseResult(['closingHoursDtos.from', 'closingHoursDtos.until'])
    @Get('/nox/public/v1/studios/{studioId}')
    public getStudioPublic(_p0: any): any {
        /**/
    }

    @parseResult(['closingHoursDtos.from', 'closingHoursDtos.until'])
    @Get('/nox/public/v1/studios')
    public getStudiosPublic(): any {
        /**/
    }

    @Get('/nox/v1/studios/{studioId}/utilization')
    public getUtilization(_p0: any): any {
        /**/
    }

    @Get('/nox/public/v1/studios/{studioId}/utilization')
    public getUtilizationPublic(_p0: any): any {
        /**/
    }
}

// tslint:enable:no-any

export function getClient(
    endpoint: string,
    token: string | undefined,
    configure: (client: Pretend) => Pretend = client => client
): NoExcuse {
    const bearerToken = (request: IPretendRequest) => {
        if (token) {
            request.options.headers = new Headers(request.options.headers);
            request.options.headers.set('Authorization', `bearer ${token}`);
        }
        return request;
    };

    return {
        NoxBookableItemPublicController: configure(Pretend.builder())
            .requestInterceptor(bearerToken)
            .target(NoxBookableItemPublicControllerBlueprint, endpoint),
        NoxBookableItemPublicControllerV2: configure(Pretend.builder())
            .requestInterceptor(bearerToken)
            .target(NoxBookableItemPublicControllerV2Blueprint, endpoint),
        NoxStudioController: configure(Pretend.builder())
            .requestInterceptor(bearerToken)
            .target(NoxStudioControllerBlueprint, endpoint)
    };
}
